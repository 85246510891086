<script setup lang="ts">
import WebMap from '@arcgis/core/WebMap.js'
import MapView from '@arcgis/core/views/MapView.js'
import FeatureLayer from '@arcgis/core/layers/FeatureLayer.js'
import { when } from '@arcgis/core/core/reactiveUtils.js'

const container = ref<HTMLDivElement>()

const { extraLayersDisplayed, featureId, layers, mainLayerDisplayed } = await useMapLayers()

const {
   latitude,
   longitude,
   setPosition,
   zoom,
} = useMapPosition()

const localeRoute = useLocaleRoute()
const route = useRoute()
const mapLayers = toValue(layers).map(layer => new FeatureLayer(layer))

/**
 * Create the map.
 */
const map = new WebMap({
   basemap: {
      portalItem: {
         id: '979c6cc89af9449cbeb5342a439c6a76',
      },
   },
   layers: mapLayers,
})

/**
 * Create the map view.
 */
const view = new MapView({
   center: [toValue(longitude), toValue(latitude)],
   map,
   zoom: toValue(zoom),
})

/**
 * Get the layer view.
 */
const mainLayer = computed(() => {
   return mapLayers.find((layer) => {
      return layer.id === toValue(mainLayerDisplayed)
   })
})

/**
 * Highlight graphic.
 */
async function highlight(graphic: __esri.Graphic) {
   const layer = toValue(mainLayer)
   if (!layer)
      return
   const layerView = await view.whenLayerView(layer)
   const color = layer.renderer.get('symbol.color') as __esri.Color
   layerView.highlightOptions = {
      color: { ...color, a: 0.75 } as __esri.Color,
   }
   layerView.highlighted?.remove()
   layerView.highlighted = layerView.highlight(graphic)
}

/**
 * Get selection.
 */
async function getSelection(event: __esri.ViewClickEvent) {
   const layer = toValue(mainLayer)
   if (!layer)
      return
   const { results } = await view.hitTest(event, { include: [layer] })
   const objects = results.filter((result): result is __esri.GraphicHit => 'graphic' in result)
   if (!objects?.length)
      return
   return objects
}

/**
 * Go to selection page.
 */
async function navigateToSelectionPage(graphic: __esri.Graphic) {
   const idKey = graphic.layer.idKey
   const id = graphic.attributes[idKey]

   const link = localeRoute({
      name: `${graphic.layer.id}-id`,
      params: { id },
      query: route.query,
   })

   if (!link)
      return

   await navigateTo(link)
}

/**
 * Get go to target.
 */
function getGoToTarget(layerId: string, id: string, graphic: __esri.Graphic) {
   if (layerId !== 'regions')
      return graphic

   switch (id) {
      case '4': // Europe
         return {
            center: [51.7044808816783, 39.74922180176472],
         }
      case '6': // The Americas
         return {
            center: [-74.37213801021737, 19.17578682299011],
         }
      case '2': // Antarctica
         return {
            center: [-155.87489985111185, -82.68186670290757],
         }
      default:
         return graphic
   }
}

/**
 * Get all graphics on the main layer.
 */
async function setSelection() {
   const id = toValue(featureId)
   const layer = toValue(mainLayer)
   if (!id || !layer)
      return

   await view.whenLayerView(layer)
   const idKey = layer.idKey
   const { features } = await layer.queryFeatures({
      num: 1,
      outFields: ['OBJECTID', layer.objectIdField],
      returnGeometry: true,
      where: `${idKey} = '${id}'`,
   })
   const selected = features[0]
   if (!selected)
      return
   const goToTarget = getGoToTarget(layer.id, id, selected)
   await view.goTo(goToTarget, { animate: true })
   await highlight(selected)
}

/**
 * Handle click event.
 */
async function handleClick(event: __esri.ViewClickEvent) {
   const selection = await getSelection(event)
   if (!selection?.[0])
      return
   const { graphic } = selection[0]
   await navigateToSelectionPage(graphic)
}

/**
 * Set container for the map.
 */
function setContainer() {
   if (!view || !container.value)
      return
   view.container = container.value
}

/**
 * Set view position from query.
 */
function setViewPosition() {
   view.set({
      center: [toValue(longitude), toValue(latitude)],
      zoom: toValue(zoom),
   })
}

/**
 * Set query position from view.
 */
when(() => view.stationary === true, () => {
   setPosition({
      lat: view.center.latitude,
      lng: view.center.longitude,
      zoom: view.zoom,
   })
})

/**
 * Set main layer visibility.
 */
function setMainLayerVisibility() {
   mapLayers.forEach((layer) => {
      if (layer.isExtra)
         return
      layer.visible = layer.id === toValue(mainLayerDisplayed)
   })
}

/**
 * Set extra layers visibility.
 */
function setExtraLayersVisibility() {
   mapLayers.forEach((layer) => {
      if (!layer.isExtra)
         return
      layer.visible = toValue(extraLayersDisplayed)?.includes(layer.id)
   })
}

view.on('click', handleClick)

// watchEffect(setViewPosition)
watchEffect(setSelection)
watchEffect(setContainer)
watchEffect(setMainLayerVisibility)
watchEffect(setExtraLayersVisibility)
</script>

<template>
   <div ref="container" />
</template>
